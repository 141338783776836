import React from 'react';

import { DateIcon } from '../Icons/icons';
import { MainColour } from '../../utils/variables';

export default function PostMeta({ date, margin }) {
  return (
    <ul
      className="latestInfo"
      style={{
        margin: `0 0 ${margin || `60px`} 0`,
      }}
    >
      <li
        className="noBullet"
        style={{
          display: `flex`,
          alignItems: `center`,
          gap: `30px`,
          color: `#ACB4C0`,
        }}
      >
        <DateIcon color={MainColour} /> Posted {date}
      </li>
    </ul>
  );
}
